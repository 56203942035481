import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { GhostPost } from 'types/ghost'
import { centeredContent, typography, colors, linkUnderline } from 'styles'
import BackgroundImage from 'components/BackgroundImage'
import PostAuthors from 'components/Post/PostAuthors'

interface Props {
  post: GhostPost
}

const Header = styled.div({
  marginBottom: '1.6rem',
})

const HeaderImage = styled.div({
  minHeight: 200,
  maxHeight: 542,
  height: '36.1vw',
  width: '100%',
  overflow: 'hidden',
})

const PostHeader = ({ post }: Props) => {
  return (
    <Header>
      {!!post.feature_image && (
        <HeaderImage>
          <BackgroundImage
            fluid={post.feature_image.localFile.childImageSharp.cover}
          />
        </HeaderImage>
      )}
      <div css={[centeredContent(), { marginTop: '2rem' }]}>
        {/* post meta */}
        <div css={{ ...typography.caption }}>
          {!!post.primary_tag && (
            <Link
              css={{
                textTransform: 'uppercase',
                textDecoration: 'none',
                marginRight: '0.4rem',
                fontWeight: 700,
                color: colors.link
              }}
              to={post.primary_tag.fields.permalink}
            >
              {post.primary_tag.name}
            </Link>
          )}
        </div>

        <h1
          css={{
            ...typography.headline1,
            margin: '0.1rem 0 0.2rem -1px',
          }}
        >
          {post.title}
        </h1>
        <PostAuthors post={post}></PostAuthors>
        {/* <span css={{ color: 'rgba(0,0,0,0.6)' }}>
          By
          {' '}
          {authors}
          {' '}
          on {publishedAt} • <span>{estReadingTime}</span>
        </span> */}
      </div>
    </Header>
  )
}

export default PostHeader
