import React from 'react'
import Helmet from 'react-helmet'
import { GhostPost, GhostSettings } from 'types/ghost'
import { withPrefix } from 'gatsby'

interface Props {
  baseUrl: string
  settings: GhostSettings
  post: GhostPost
}

const getImageForPost = (post: GhostPost) => {
  if (!!post.og_image) {
    return {
      ...post.og_image.localFile.childImageSharp.resize,
      mediaType: post.og_image.localFile.internal.mediaType,
    }
  } else if (!!post.feature_image) {
    return {
      ...post.feature_image.localFile.childImageSharp.resize,
      mediaType: post.feature_image.localFile.internal.mediaType,
    }
  }

  return undefined
}

const createMetaTag = (id: string, property: [string, string]) => {
  const [name, content] = property
  if (!content) return null

  return /^og|^article/.test(name) ? (
    <meta key={`${id}-${name}`} property={name} content={content} />
  ) : (
    <meta key={`${id}-${name}`} name={name} content={content} />
  )
}

const mapPostToProperties = (
  baseUrl: string,
  post: GhostPost,
  settings: GhostSettings
) => {
  const image = getImageForPost(post)

  return {
    description: !!post.meta_description
      ? post.meta_description
      : post.custom_excerpt,
    'og:type': 'article',
    'og:title': !!post.og_title ? post.og_title : post.title,
    'og:description': !!post.og_description
      ? post.og_description
      : post.custom_excerpt,
    'og:url': `${baseUrl}${post.fields.permalink}`,
    'og:image': image && `${baseUrl}${image.src}`,
    'og:image:width': image && image.width,
    'og:image:height': image && image.height,
    'og:image:type': image && image.mediaType,
    authors: post.authors,
    author: post.primary_author.name,
    'article:publisher':
      !!settings.facebook && `https://facebook.com/${settings.facebook}`,
    'article:author':
      !!post.primary_author.facebook &&
      `https://facebook.com/${post.primary_author.facebook}`,
    'article:published_time': post.published_at,
    'article:modified_time': post.updated_at,
    'article:tag': !!post.tags
      ? post.tags.filter(tag => tag.visibility === 'public')
      : [],
    'twitter:card': 'summary_large_image',
    'twitter:site': settings.twitter,
    'twitter:title': post.twitter_title,
    'twitter:description': post.twitter_description,
    'twitter:image':
      !!post.twitter_image &&
      `${baseUrl}${post.twitter_image.localFile.childImageSharp.resize.src}`,
    'twitter:creator': post.primary_author.twitter,
  }
}

const getMetaTags = (
  baseUrl: string,
  post: GhostPost,
  settings: GhostSettings
) =>
  Object.entries(mapPostToProperties(baseUrl, post, settings)).map(property => {
    const [name, content] = property
    if (name === 'article:tag') {
      return content.map(tag =>
        createMetaTag(`${post.id}-${tag.id}`, [name, tag.name])
      )
    }
    return createMetaTag(post.id, property)
  })

const getStructuredData = (baseUrl: string, post: GhostPost) => {
  const image = getImageForPost(post)
  return [
    <script key={`${post.id}-structuredData`} type="application/ld+json">{`
      {
        "@context": "http://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "${baseUrl}${post.fields.permalink}"
        },
        "headline": "${post.title}",
        ${
          image
            ? `"image": {
          "@type": "ImageObject",
          "width": ${image.width},
          "height": ${image.height},
          "url": "${baseUrl}${image.src}"
        },`
            : ''
        }
        "datePublished": "${post.published_at}",
        "dateModified": "${post.updated_at}",
        "author": {
          "@type": "Person",
          "name": "${post.primary_author.name}",
          "url": "${baseUrl}${post.primary_author.fields.permalink}"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Project Evident",
          "url": "https://www.projectevident.org",
          "logo": {
            "@type": "ImageObject",
            "url": "${baseUrl}${withPrefix('pe-amp-logo.png')}",
            "width": 600,
            "height": 60
          }
        },
        "description": "${
          !!post.meta_description ? post.meta_description : post.custom_excerpt
        }"
      }
  `}</script>,
  ]
}

const PostSEO = ({ baseUrl, settings, post }: Props) => (
  <Helmet title={post.title}>
    {[
      <html
        key={`${post.id}-html`}
        lang="en"
        prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# article: http://ogp.me/ns/article#"
      />,
      ...getMetaTags(baseUrl, post, settings),
      ...getStructuredData(baseUrl, post),
    ]}
  </Helmet>
)

export default PostSEO
