import React from 'react'
import { graphql } from 'gatsby'
import { Global } from '@emotion/core'
import styled from '@emotion/styled'
import htmlParse, { domToReact } from 'html-react-parser'
import { renderToStaticMarkup } from 'react-dom/server'

import { GhostPost, GhostSettings } from 'types/ghost'
import Layout from 'components/Layout'
import PostHeader from 'components/Post/PostHeader'
import PostFooter from 'components/Post/PostFooter'
import { markdown, centeredContent } from 'styles'
import Header from 'components/Header'
import PostShare from 'components/Post/PostShare'
import PostTags from 'components/Post/PostTags'
import PostSEO from 'components/Post/PostSEO'

const PostContent = styled.div(centeredContent())

interface Props {
  data: {
    ghostPost: GhostPost
    ghostSettings: GhostSettings
    site: {
      siteMetadata: {
        baseUrl: string
      }
    }
  }
}

const PostTemplate = ({
  data: { ghostPost: post, site, ghostSettings },
}: Props) => {
  const options = {
    replace: ({name, children, attribs}) => {
      const localAddress = /projectevident.org/;

      if (name === "a" && attribs && attribs.href && !localAddress.test(attribs.href)) {
        return <a {...attribs} target="_blank">{ domToReact(children, options) }</a>;
      }
    }
  };

  const html = renderToStaticMarkup(htmlParse(post.html, options));

  return (
    <Layout>
      <Global
        styles={{
          html: {
            fontSize: '90%',
            '@media (min-width: 760px)': {
              fontSize: '100%',
            },
          },
        }}
      />
      <PostSEO
        baseUrl={site.siteMetadata.baseUrl}
        settings={ghostSettings}
        post={post}
      />
      <Header />
      <PostHeader post={post} />
      <PostContent
        css={markdown}
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div
        css={[
          centeredContent(),
          {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1.6rem',
          },
        ]}
      >
        {post.tags && <PostTags tags={post.tags} />}
        <PostShare baseUrl={site.siteMetadata.baseUrl} post={post} />
      </div>
      <PostFooter {...post.primary_author} />
    </Layout>
  );
}

export default PostTemplate

export const query = graphql`
  fragment ogImage on GhostMedia {
    src
    localFile {
      internal {
        mediaType
      }
      childImageSharp {
        resize(width: 1200, height: 630, cropFocus: ENTROPY) {
          src
          width
          height
        }
      }
    }
  }

  fragment twitterImage on GhostMedia {
    src
    localFile {
      internal {
        mediaType
      }
      childImageSharp {
        resize(width: 1024, height: 512, cropFocus: ENTROPY) {
          src
          width
          height
        }
      }
    }
  }

  fragment ghostPostSEO on GhostPost {
    title
    custom_excerpt
    feature_image {
      ...ogImage
    }
    meta_title
    meta_description
    og_title
    og_description
    og_image {
      ...ogImage
    }
    twitter_title
    twitter_description
    twitter_image {
      ...twitterImage
    }
    authors {
      name
      profile_image {
        src
      }
      fields {
        permalink
      }
    }
    primary_author {
      twitter
      facebook
    }
  }

  query($ghostId: String!) {
    ...siteMeta
    ghostSettings {
      twitter
      facebook
    }
    ghostPost(ghostId: { eq: $ghostId }) {
      ...ghostPostDetails
      ...ghostPostSEO
      feature_image {
        localFile {
          childImageSharp {
            cover: fluid(maxWidth: 1500, maxHeight: 750) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
