import React from 'react'
import styled from '@emotion/styled'
import { colors } from 'styles'
import { GhostPost } from 'types/ghost'

const ShareButton = styled.a({
  flexShrink: 0,
  margin: '0.4rem 0',
  padding: '0.4rem',
  color: '#939393',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const ShareButtons = styled.div({
  display: 'flex',
  marginLeft: 'auto',
  flex: '0 0 auto',
})

const FacebookIcon = ({ width, height }) => (
  <svg
    aria-hidden="true"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 264 512"
    width={width}
    height={height}
  >
    <path
      fill="currentColor"
      d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
    />
  </svg>
)

const TwitterIcon = ({ width, height }) => (
  <svg
    aria-hidden="true"
    data-prefix="fab"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={width}
    height={height}
  >
    <path
      fill="currentColor"
      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
    />
  </svg>
)

// NOTE: Icon taken from https://iconmonstr.com/linkedin-3-svg/, suppose kinda attribute or replacement needed
const LinkedInIcon = ({ width, height }) => (
  <svg
    aria-hidden="true"
    data-prefix="fab"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
  >
    <path
      fill="currentColor"
      d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
    />
  </svg>
)

// NOTE: Icon taken from https://iconmonstr.com/email-3-svg/, suppose kinda attribute or replacement needed
const MailToIcon = ({ width, height }) => (
  <svg
    aria-hidden="true"
    data-prefix="fab"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
  >
    <path
      fill="currentColor"
      d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"
    />
  </svg>
)

const objectToGetParams = (obj: object) =>
  `?${Object.entries(obj)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')}`

const openWindow = ({
  href,
  name,
  width,
  height,
}: {
  href: string
  name: string
  width: number
  height: number
}) => {
  const left =
    window.outerWidth / 2 +
    (window.screenX || window.screenLeft || 0) -
    width / 2
  const top =
    window.outerHeight / 2 +
    (window.screenY || window.screenTop || 0) -
    height / 2
  window.open(
    href,
    name,
    `location=no,status=no,directories=no,centerscreen=yes,chrome=yes,menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=${width},height=${height},top=${top},left=${left}`
  )
}

const PostShare = ({
  className,
  baseUrl,
  post,
}: {
  className?: string
  baseUrl: string
  post: GhostPost
}) => (
  <ShareButtons className={className}>
    <ShareButton
      href={`https://www.facebook.com/sharer.php${objectToGetParams({
        u: `${baseUrl}${post.fields.permalink}`,
      })}`}
      rel="noopener noreferrer"
      onClick={e => {
        e.preventDefault()
        openWindow({
          href: e.currentTarget.href,
          name: 'fbShare',
          width: 626,
          height: 436,
        })
      }}
    >
      <FacebookIcon width={21} height={21} />
    </ShareButton>

    <ShareButton
      href={`https://twitter.com/intent/tweet${objectToGetParams({
        text: `${post.title}`,
        url: `${baseUrl}${post.fields.permalink}`,
      })}`}
      rel="noopener noreferrer"
      onClick={e => {
        e.preventDefault()
        openWindow({
          href: e.currentTarget.href,
          name: 'twitterShare',
          width: 626,
          height: 275,
        })
      }}
    >
      <TwitterIcon width={21} height={21} />
    </ShareButton>

    <ShareButton
      href={`http://www.linkedin.com/shareArticle${objectToGetParams({
        'mini': true,
        'url': `${baseUrl}${post.fields.permalink}`,
        'title': post.title
      })}`}
      rel="noopener noreferrer"
      onClick={e => {
        e.preventDefault()
        openWindow({
          href: e.currentTarget.href,
          name: 'linkedinShare',
          width: 626,
          height: 275,
        })
      }}
    >
      <LinkedInIcon width={21} height={21} />
    </ShareButton>

    <ShareButton
      href={`mailto:${objectToGetParams({
        subject: post.title,
        body: `${baseUrl}${post.fields.permalink}`
      })}`}
      rel="noopener noreferrer"
      onClick={e => {
        e.preventDefault()
        openWindow({
          href: e.currentTarget.href,
          name: 'mailtoShare',
          width: 626,
          height: 275,
        })
      }}
    >
      <MailToIcon width={21} height={21} />
    </ShareButton>
  </ShareButtons>
)

export default PostShare
