import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { GhostTag } from 'types/ghost'
import { typography } from 'styles'

interface Props {
  tags: GhostTag[]
}

const TagsList = styled.ul({
  listStyle: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  marginBottom: '-0.5rem',
  marginRight: '0.8rem',
  flex: 1,
  minWidth: 0,
})

const TagLink = styled(Link)({
  ...typography.caption,
  display: 'inline-block',
  padding: '4px 6px',
  color: '#596065',
  backgroundColor: '#F0F0F0',
  borderRadius: 2,
  marginRight: '0.5rem',
  marginBottom: '0.5rem',
  textDecoration: 'none',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '100%',
  verticalAlign: 'middle',
})

const PostTags = ({ tags }: Props) => (
  <TagsList>
    {tags
      .filter(t => t.visibility === 'public')
      .map(tag => (
        <li key={tag.id} css={{ minWidth: 0 }}>
          <TagLink to={tag.fields.permalink}>{tag.name}</TagLink>
        </li>
      ))}
  </TagsList>
)

export default PostTags
