import React, { Component } from 'react'
import styled from '@emotion/styled'
import { FluidObject } from 'gatsby-image'

const Background = styled.div({
  transition: 'filter 0.5s ease',
  transform: 'scale(1.04)',
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
})

interface Props {
  fluid: FluidObject
}

interface State {
  src: string
  blur: number
}

const imageCache: Set<string> = new Set()

class BackgroundImage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const {
      fluid: { base64, src },
    } = this.props

    const isInCache = imageCache.has(src)

    this.state = {
      src: isInCache ? src : base64 || '',
      blur: isInCache ? 0 : 16,
    }
  }

  componentDidMount() {
    const {
      fluid: { src = '' },
    } = this.props

    if (imageCache.has(src)) {
      return
    }

    const image = new Image()
    image.addEventListener('load', () => {
      this.imageLoaded()
    })
    image.src = src
  }

  imageLoaded = () => {
    const {
      fluid: { src },
    } = this.props
    this.setState({ src, blur: 0 })
    imageCache.add(src)
  }

  render() {
    const { blur, src } = this.state
    return (
      <Background
        style={{ backgroundImage: `url(${src})`, filter: `blur(${blur}px)` }}
      />
    )
  }
}

export default BackgroundImage
