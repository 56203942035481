import React from 'react'
import { Link } from 'gatsby'

import { typography, centeredContent, linkUnderline } from 'styles'

const smallBreakpoint = '@media (min-width: 450px)'

import peLogo from 'images/pe-logo.svg';


const PostFooter = () => (
  <div css={centeredContent()}>
    <div
      css={{
        display: 'flex',
        borderTop: '1px solid #dfe2e5',
        paddingTop: '1.6rem',
        marginBottom: '3.2rem',
      }}
    >
      <img
        css={{
          height: '75px',
          width: '75px',
        }}
        src={peLogo}
      />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 auto',
          marginLeft: '0.8rem',
          alignItems: 'flex-start',
          [smallBreakpoint]: {
            marginLeft: '1.6rem',
          },
        }}
      >

        <p css={{ ...typography.body, color: 'rgba(0,0,0,0.6)' }}>
          Need more help? <a css={[linkUnderline, { fontWeight: 700 }]} href="mailto:evidencetoolkit@projectevident.org">Contact us</a> today to find out how Project Evident can help your organization better
          use evidence to improve outcomes for the communities you serve.
        </p>
      </div>
    </div>
  </div>
)

export default PostFooter
