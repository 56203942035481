import React from 'react'
import styled from '@emotion/styled';
import { colors } from "styles";
import { format } from 'date-fns'
import { Link } from 'gatsby'

import { GhostPost } from "types/ghost";

import estimateReadingTime from 'lib/estimateReadingTime'
import { POST_DATE_FORMAT } from 'lib/constants'

import emptyAvatarImage from 'images/empty-avatar-image.png';

interface Props {
    post: GhostPost,
    small?: boolean
}

const Container = styled.div({
    borderColor: 'transparent',
    borderTopColor: colors.gray,
    borderWidth: '0.5px',
    borderStyle: 'solid',
    display: 'flex',
    paddingTop: '8px'
});

const AvatarContainer = styled.div({
    maxWidth: '100px',
    marginLeft: '15px',
    marginRight: '10px'
});

const AuthorsContainer = styled.div({
    flexGrow: 1
});

const PostMeta = styled.div({
    color: colors.gray,
    textTransform: 'uppercase',
});

const Avatar = styled.div({
    width: '40px',
    height: '40px',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    borderColor: 'white',
    borderWidth: '1.5px',
    borderRadius: '50%',
    borderStyle: 'solid',
    display: 'inline-block',
    marginLeft: '-15px',
});

const AuthorLink = styled(Link)({
    color: colors.primary,
    textDecoration: 'none',
    fontWeight: 300,
    textTransform: 'uppercase',
});

const PostAuthors = ({ post, small }: Props) => {
    const publishedAt = format(post.published_at, POST_DATE_FORMAT)
    const estReadingTime = estimateReadingTime(post.html)

    const authorsAvatars = (post.authors || [])
        .map((author, index) => {
            const src = (author.profile_image && author.profile_image.src) ? author.profile_image.src : emptyAvatarImage;
            return <Avatar
                key={index}
                style={{
                    backgroundImage: `url(${src})`,
                    width: `${small ? 30 : 40 }px`,
                    height: `${small ? 30 : 40}px`,
                    marginLeft: `${small ? -10 : -15}px`,
                }}
                title={author.name}
            ></Avatar>;
        });

    const authorsNames = post.authors
        .map((author, index) =>
            <AuthorLink
                key={index}
                to={author.fields.permalink}

            >{ author.name }</AuthorLink>
        )
        .map((ctrl: any, index: number) => [
            index > 0 && ", ",
            ctrl
        ]);

    return (
        <Container>
            <AvatarContainer
                style={{
                    marginLeft: `${small ? 10 : 15}px`,
                    marginRight: `${small ? 4 : 10}px`,
                }}
            >
                { authorsAvatars }
            </AvatarContainer>

            <AuthorsContainer>
                <div>
                    {authorsNames }
                </div>

                <PostMeta>
                    <span>{publishedAt}</span>
                    { ' • ' }
                    <span>{estReadingTime }</span>
                </PostMeta>
            </AuthorsContainer>
        </Container>
    );
}

export default PostAuthors;
